// src/store/rolesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBranding } from '../types/reduxStore';

const initialState: IBranding = {
    primaryColor: localStorage.getItem('formsPrimaryColor') ?? '#1e88e5',
    logoBase64: localStorage.getItem('logoBase64')
};

export const brandingSlice = createSlice({
    name: 'branding',
    initialState,
    reducers: {
        updateBranding: (state, action: PayloadAction<IBranding>) => {
            if (action.payload) {
                state.primaryColor = action.payload.primaryColor;
                state.logoBase64 = action.payload.primaryColor
            } else {
                return initialState;
            }
        },
    },
});

export const { updateBranding } = brandingSlice.actions;

export default brandingSlice.reducer;
