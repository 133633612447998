import { MaterialLayoutRenderer, MaterialLayoutRendererProps } from '@jsonforms/material-renderers';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
//import React, { useState, useContext, useEffect } from 'react';
//import { withJsonFormsLayoutProps, JsonFormsContext } from '@jsonforms/react';
import React from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

const AccordionGroupControl = (props) => {
    const { uischema, schema, path, visible, renderers } = props;

    const layoutProps: MaterialLayoutRendererProps = {
        elements: uischema.elements,
        schema: schema,
        path: path,
        direction: 'column',
        visible: visible,
        uischema: uischema,
        renderers: renderers,
    };

    // TODO: I'm sure we wil need to indicate something in the accordion header at some point... so start here...
    /*    const [allFieldsCompleted, setAllFieldsCompleted] = useState(false);
       const context = useContext(JsonFormsContext);
       const formData = context.core!.data;
   
       useEffect(() => {
           // TODO: currently checking all fields in an accordion group. maybe make it all required fields instead.
           const checkCompletion = () => {
               if (formData) {
   
                   const fieldCountInAccordionGroup: number = uischema.elements.length;
                   let completedFieldCount: number = 0;
   
                   uischema.elements.forEach(field => {
   
                       const scope: string = field.scope;
   
                       const fieldName1: string = scope.split('/').pop() || "";
   
                       Object.entries(formData).forEach(([fieldName, fieldValue]) => {
                           if (fieldName === fieldName1) {
                               if (fieldValue) {
                                   completedFieldCount = completedFieldCount + 1
                               }
                           }
                       })
                   })
   
                   if (completedFieldCount === fieldCountInAccordionGroup) {
                       setAllFieldsCompleted(true);
                   }
                   else {
                       setAllFieldsCompleted(false);
                   }
               }
           };
   
           checkCompletion();
   
           // Put this in HTML
           //{allFieldsCompleted && <CheckCircleIcon style={{ color: 'green', marginLeft: '10px' }} />}
           //{!allFieldsCompleted && <RemoveCircleIcon style={{ color: 'red', marginLeft: '10px' }} />}
   
       }, [formData]); */

    return (
        <Hidden xsUp={!visible}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{uischema.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MaterialLayoutRenderer {...layoutProps} />
                </AccordionDetails>
            </Accordion>
        </Hidden>
    );
};

export default withJsonFormsLayoutProps(AccordionGroupControl);