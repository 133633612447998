import React from 'react';
// import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PageHeader = ({ title, onBackClick, showBackButton = false }) => {
    return (
        // <AppBar position="static">
        <Toolbar className="page-header">
            <Typography variant="h6" style={{ flexGrow: 1 }}>{title}</Typography>
            {showBackButton && onBackClick && (
                <Button onClick={onBackClick} startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                // <IconButton edge="start" color="inherit" onClick={onBackClick}>
                //     <ArrowBackIcon />
                // </IconButton>
            )}
        </Toolbar>
        // </AppBar>
    );
}

export default PageHeader;
