import { Fragment, memo, useEffect, useState } from 'react';
import { Button, InputLabel } from '@mui/material';
// import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { ReactElement } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import ImageIcon from '@mui/icons-material/Image';


interface ISelectedFile {
    fileId: number;
    base64: string;
    name: string;
}

const ImageCaptureControl = memo((props: any): ReactElement => {
    const {
        data,
        label,
        id,
        enabled,
        uischema,
        errors,
        handleChange,
        options,
        path,
        schema,
        uischemas,
    } = props;

    const [files, setFiles] = useState<ISelectedFile[]>([]);
    const [accept, setAccept] = useState<string>("image/*");
    const [multiple, setMultiple] = useState<boolean>(false);

    const imageSelectionChange = async ($event) => {
        if ($event.target.files && $event.target.files.length > 0) {
            const fileList: FileList = $event.target.files;

            const selectedFiles: ISelectedFile[] = [];
            await Array.from(fileList).forEach(async (file: File) => {
                const base64: string = await toBase64(file) as string;
                const selectedFile: ISelectedFile = { fileId: 0, base64: base64, name: file.name };
                selectedFiles.push(selectedFile);
                setFiles([...selectedFiles]);
            });

            if (uischema && uischema.options && uischema.options.multiple) {
                handleChange(path, selectedFiles);
            } else {
                handleChange(path, selectedFiles);
            }
        }
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


    useEffect(() => {
        if (uischema && uischema.options && uischema.options.accept)
            setAccept(uischema.options.accept);

        setMultiple(false);
        if (uischema && uischema.options && uischema.options.multiple)
            setMultiple(true);

        if (data && data.length > 0) {
            setFiles([...data]);
        }

    }, [schema, uischema]);

    return (
        <div className="image-capture-container">
            <Fragment>
                <InputLabel >{label}</InputLabel>
                <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                    <Button component="label" variant="outlined" startIcon={<ImageIcon />}>
                        Select Image
                        <input
                            accept={accept}
                            style={{ display: 'none' }}
                            id={`icon_${props.path}`}
                            onChange={imageSelectionChange}
                            type="file"
                            multiple={multiple}
                        />
                    </Button>
                </label>

                <div id="image-list-container" className="image-list-container">
                    {files.map((item, index) => (
                        <img
                            key={index}
                            src={item.base64}
                            alt={item.name}
                            height="100px"
                            width="100px"
                            loading="lazy"
                        />
                    ))}

                </div>

                {/* {files && <ImageList sx={{ width: '100%', height: 100 }} cols={2} rowHeight={200}>
                    {files.map((item, index) => (
                        <ImageListItem key={index}>
                            <img
                                src={`${URL.createObjectURL(item.file)}`}
                                alt={item.file.name}
                                height="100px"
                                width="100px"
                                loading="lazy"
                            />
                            <ImageListItemBar
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white' }}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                }
                                actionPosition="right"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>} */}
            </Fragment>
        </div>
    );
});

export default withJsonFormsControlProps(ImageCaptureControl);
