import ReactDOM from 'react-dom';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Auth0Provider } from '@auth0/auth0-react';
import { appConfig } from './services/config.service';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {

    }, err => {

    });
  });
}

// Call the element loader before the render call
defineCustomElements(window);

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={appConfig.AUTH0_DOMAIN}
      clientId={appConfig.AUTH0_CLIENTID}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: appConfig.AUTH0_REDIRECTURI,
        audience: appConfig.AUTH0_AUDIENCE
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root')
);


