import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import CustomerList from '../components/CustomerList';
import PageHeader from '../components/PageHeader';
import { CreateCustomer, getCustomerList, UpdateCustomer } from '../services/customer.service';
import { useStyles } from "../common/appStyles";
import { Button } from '@mui/material';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';
import CustomerDialog from '../components/CustomerDialog';
import { ICustomer } from '../types/customer';
import { useNavigate } from 'react-router-dom';


function Customers(props) {
    const classes = useStyles();

    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.Customers];
    const [rows, setRows] = useState<ICustomer[]>([]);
    const user = useSelector((state: RootState) => state.userDetail);

    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const [showBackButton] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState<ICustomer>();

    const [pageTitle] = useState("Customers");
    const [titleText, setTitleText] = useState("");

    const handleClose = () => {
        setShowCustomerDialog(false);
    }

    const handleSave = async (customer: ICustomer, formData: FormData) => {
        try {
            if (customer.customerId === 0 && customer.createdDate == null && customer.customerName != 'Sho Laboratories Admin') {

                await CreateCustomer(formData);
            }
            else {
                await UpdateCustomer(formData);
            }
            showSnackbar('Saved Successfully', 'success', 5000);
            setShowCustomerDialog(false);
            GetCustomerList();
        } catch (error) {
            showSnackbar('Error saving data', 'error', 5000);
        }
    }

    const onEditCustomer = (row: any) => {
        setTitleText("Update Customer");
        setSelectedCustomer(row);
        setShowCustomerDialog(true);
    }


    const GetCustomerList = () => {
        const request = {
            userId: user.userId,
        };
        getCustomerList(request)
            .then(response => {
                setRows(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        GetCustomerList();
    }, []);

    const onAddNewCustomer = () => {
        setTitleText("Add Customer");
        setSelectedCustomer({
            customerId: 0,
            customerName: "",
            isActive: true,
            isDeleted: false,
            primaryColor: "",
            secondaryColor: "",
            cloudFileName: "",
            logoUrl: "",
            createdBy: 0,
            createdDate: null,
            modifiedBy: 0,
            modifiedDate: null,
            logo: null,
            formIdList: []
        });
        setShowCustomerDialog(true);
    }
    const onBack = () => { }
    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12} className='justify-center'>
                    <PageHeader title={pageTitle} showBackButton={showBackButton} onBackClick={onBack} />
                    {currentPage?.canView && currentPage?.canAdd &&
                        <Button className='btn-theme-toggle' onClick={() => onAddNewCustomer()} variant="contained">
                            Add New Customer
                        </Button>}
                </Grid>
                {currentPage?.canView && <Grid item sm={12}>
                    {!showCustomerDialog && <CustomerList onEditCustomer={onEditCustomer} rows={rows} />}
                    {showCustomerDialog && <CustomerDialog
                        showDialog={showCustomerDialog}
                        selectedCustomer={selectedCustomer}
                        titleText={titleText}
                        handleClose={handleClose}
                        handleSave={handleSave}
                    />}
                </Grid>}
                {!currentPage?.canView && <h1>You don't have access to Customers Page</h1>}
            </Grid>
        </Fragment>
    );
}
export default Customers;
