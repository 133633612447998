import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth0 } from '@auth0/auth0-react';

import { GetFormDetailList } from '../services/form-service';

import FormSettingDialog from '../components/FormSettingDialog';

import { IForm, IFormListRequest, IUserFormGroupListRequest, IUserFormGroup } from '../types/forms';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum, RoleEnum } from '../types/enums';
import { showSnackbar } from '../services/snackbar.service';
import { GetUserFormGroupList } from '../services/form-group-service';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { updateSelectedForm } from '../store/selectedFormSlice';
import { DownloadExcel } from '../services/form-service';
import { IUserDetail } from '../types/reduxStore';
import indexedDBService from '../services/indexdb-service';


const FormList = ({ onAddNewEntry }) => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUserDetail>(useSelector((state: RootState) => state.userDetail))
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.MyForms];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formListMaster, setFormListMaster] = useState<IForm[]>([]);
    const [formListDisplay, setFormListDisplay] = useState<IForm[]>([]);
    const [selectedForm, setSelectedForm] = useState<IForm>();

    const [userFormGroupList, setUserFormGroupList] = useState<IUserFormGroup[]>([]);
    const [selectedFormGroupId, setSelectedFormGroupId] = useState<number>(0);

    const [isReadOnlyFormGroup, setIsReadOnlyFormGroup] = useState(false);
    const [showFormSettingDialog, setShowFormSettingDialog] = useState(false);
    const [formGroupAllowsEditing, setFormGroupAllowsEditing] = useState(true);

    const { isLoading } = useAuth0();

    const waitForAuth0Loading = async (): Promise<void> => {
        return new Promise<void>((resolve) => {
            const checkInterval = 10; // check every 10 milliseconds
            const intervalId = setInterval(async () => {
                const idbUser = await indexedDBService.getItem('General', 'User')
                if (!isLoading && idbUser != null) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, checkInterval);
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const showEditForm = () => {
        return [RoleEnum.SuperAdmin, RoleEnum.ShoAdmin, RoleEnum.ShoUser, RoleEnum.CustomerAdmin].indexOf(user.roleId) >= 0;
    }

    const canProceedToFormSettings = () => {
        if (user.pageAccess) {
            let u = user.pageAccess.find(item => item.pageCode === PageEnum.FormSettings && item.canView === true);
            if (u) {
                return true
            }
            else {
                return false
            }
        }
        return false
    }

    const updateFormsList = (selectedFormGroupIdParam: number, formListMasterParam: IForm[]) => {

        if (selectedFormGroupIdParam > 0) {
            const selectedFormGroup: IUserFormGroup = userFormGroupList.find(x => x.formGroupId === selectedFormGroupIdParam)!;
            if (selectedFormGroup) {
                const filteredFormListByGroup: IForm[] = formListMaster.filter(x => selectedFormGroup.formIdList.indexOf(x.formId) >= 0);
                setFormListDisplay(filteredFormListByGroup);
                setIsReadOnlyFormGroup(selectedFormGroup.isReadOnlyFormGroup)
                if (selectedFormGroup.isReadOnlyFormGroup && user.roleId >= 5) {
                    setFormGroupAllowsEditing(false)
                }
                else {
                    setFormGroupAllowsEditing(true)
                }
            }
        } else {
            setFormListDisplay(formListMasterParam);
        }
    }

    const onFormGroupChange = async (selectedFormGroupId: number) => {

        setSelectedFormGroupId(selectedFormGroupId)
    }

    const onEditForm = (row: IForm) => {
        dispatch(updateSelectedForm(row));
        navigate("/form-builder/" + row.formId);
    }

    const onDownloadExcel = (row: IForm) => {
        DownloadExcel(row.customerId, row.formId)
    }

    const onViewFormEntries = (row: IForm) => {
        row.isReadOnlyMode = isReadOnlyFormGroup;
        dispatch(updateSelectedForm(row));
        navigate("/form-entries/" + row.formId + "/" + row.isReadOnlyMode);
    }

    const onViewSettings = (row: IForm) => {
        dispatch(updateSelectedForm(row));
        setSelectedForm(row);
        setShowFormSettingDialog(true);
    }

    const handleCloseFormSetting = () => {
        setShowFormSettingDialog(false)
    }

    const handleSaveFormSetting = async () => {
        try {
            showSnackbar('Saved Successfully', 'success', 5000);
            setShowFormSettingDialog(false);
        } catch (error) {
            showSnackbar('Failed to save Form Setting', 'error', 5000);
        }
    }

    const getUserFormGroupListWithDefault = async () => {
        const request: IUserFormGroupListRequest = { customerId: user.customerId, userId: user.userId };
        const formGroupList: IUserFormGroup[] = await GetUserFormGroupList(request);
        //formGroupList.splice(0, 0, { formGroupId: 0, formGroupName: "All Group(s)", customerId: -1, formIdList: [], isReadOnlyFormGroup: boolean; });
        return formGroupList;
    }

    let isLoaded = false;

    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true;
            const fetchData = async () => {
                try {

                    await waitForAuth0Loading()

                    let u = await indexedDBService.getItem('General', 'User')
                    let userDetail = u.userDetail

                    setUser(userDetail)
                    const request: IFormListRequest = {
                        customerId: userDetail.customerId,
                        userId: userDetail.userId
                    };
                    let formListMasterResponse: IForm[] = await GetFormDetailList(request);

                    setFormListMaster(formListMasterResponse)

                    let userFormGroupListResponse: IUserFormGroup[] = await getUserFormGroupListWithDefault();
                    setUserFormGroupList(userFormGroupListResponse);
                    let firstformGroupId: number = userFormGroupListResponse[0]?.formGroupId;

                    setSelectedFormGroupId(firstformGroupId);





                } catch (error) {
                    showSnackbar('Error fetching data - FORM LIST', 'error', 5000);
                }
            };
            fetchData();
        }
    }, []);

    useEffect(() => {
        updateFormsList(selectedFormGroupId, formListMaster);
    }, [selectedFormGroupId]);

    return (
        <Fragment>
            {currentPage?.canView && selectedFormGroupId > -1 && userFormGroupList && <Grid item sm={4}>
                <FormControl fullWidth>
                    <InputLabel>Form Group</InputLabel>
                    <Select
                        labelId="form-group-label"
                        value={selectedFormGroupId}
                        label="Form Group"
                        onChange={(event) => onFormGroupChange(Number(event.target.value))}
                    >
                        {userFormGroupList?.map((option: IUserFormGroup) => (
                            <MenuItem key={option.formGroupId} value={option.formGroupId}>
                                {option.formGroupName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>}
            {currentPage?.canView && <Grid
                container
                justifyContent={'center'}
                spacing={1}
            >
                <Grid item sm={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell key="formName">Form Name</TableCell>
                                        <TableCell key="formDescription">Form Description</TableCell>
                                        <TableCell key="totalRecords">Total Records</TableCell>
                                        <TableCell key="actions" align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {formListDisplay &&
                                    <TableBody>
                                        {formListDisplay
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: IForm) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.customerId + '_' + row.formId}>
                                                        <TableCell key="formName">{row.formName}</TableCell>
                                                        <TableCell key="formDescription">{row.formDescription}</TableCell>
                                                        <TableCell key="totalRecords">{row.totalRecords}</TableCell>
                                                        <TableCell key="actions" align="center">
                                                            {isMobile && <VisibilityOutlinedIcon onClick={e => onViewFormEntries(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && <Button onClick={e => onViewFormEntries(row)} className="sho-button" color='primary' size="small" variant='outlined'>
                                                                <VisibilityOutlinedIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                View All Entries
                                                            </Button>}

                                                            {isMobile && formGroupAllowsEditing && currentPage?.canAdd && <AddCircleOutlineOutlinedIcon onClick={e => onAddNewEntry(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && formGroupAllowsEditing && currentPage?.canAdd && <Button onClick={e => onAddNewEntry(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <AddCircleOutlineOutlinedIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Add Entry
                                                            </Button>}

                                                            {isMobile && formGroupAllowsEditing && canProceedToFormSettings() && currentPage?.canAdd && <SettingsIcon onClick={e => onViewSettings(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && formGroupAllowsEditing && canProceedToFormSettings() && currentPage?.canAdd && <Button onClick={e => onViewSettings(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <SettingsIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Settings
                                                            </Button>}

                                                            {showEditForm() && formGroupAllowsEditing && isMobile && <EditIcon onClick={e => onEditForm(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {showEditForm() && formGroupAllowsEditing && !isMobile && <Button onClick={e => onEditForm(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <EditIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Edit Form
                                                            </Button>}

                                                            {showEditForm() && formGroupAllowsEditing && isMobile && <FileDownloadIcon onClick={e => onDownloadExcel(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {showEditForm() && formGroupAllowsEditing && !isMobile && <Button onClick={e => onDownloadExcel(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <FileDownloadIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Download Excel
                                                            </Button>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={formListDisplay.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>


            </Grid>}
            {!currentPage?.canView && <h1>Loading Form List....</h1>}

            {showFormSettingDialog && <FormSettingDialog
                showDialog={showFormSettingDialog}
                selectedForm={selectedForm}
                handleClose={handleCloseFormSetting}
                handleSave={handleSaveFormSetting} />}
        </Fragment>
    );
}

export default FormList;
