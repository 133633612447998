import { Fragment, memo, useState } from 'react';
import { Button, List, ListItem, ListItemText, IconButton, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { withJsonFormsControlProps } from '@jsonforms/react';
import ImageIcon from '@mui/icons-material/Image';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { Capacitor, } from '@capacitor/core';
import PhotoPreviewDialog from '../../components/PhotoPreviewDialog';
import { IUserDetail } from '../../types/reduxStore';
import { IFileDetail, IForm, ISelectedFile, IUploadCloudFileRequest, IUploadCloudFileResponse } from '../../types/forms';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SyncIcon from '@mui/icons-material/Sync';
import { UploadCloudFile } from '../../services/form-service';
import indexedDBService from '../../services/indexdb-service';
import { Filesystem } from '@capacitor/filesystem';

const PhotoCaptureControl = memo((props: any): ReactElement => {
    const {
        data,
        label,
        //id,
        //enabled,
        //uischema,
        //errors,
        handleChange,
        //options,
        path,
        //schema,
        //uischemas,
        //config,
    } = props;

    const supportedImageType = "image/jpeg";
    const supportedImageExtension = ".jpg";
    const [hasCameraAccess, setHasCameraAccess] = useState(false);
    const [isUploadingToCloud, setIsUploadingToCloud] = useState(false);
    const [uploadStatusText, setUploadStatusText] = useState('');
    const [showPhotoPreviewDialog, setShowPhotoPreviewDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState<ISelectedFile>();
    const [currentPhotos, setCurrentPhotos] = useState<ISelectedFile[]>(data || []);
    const [accept] = useState<string>(supportedImageType);

    const user: IUserDetail = useSelector((state: RootState) => state.userDetail);
    const selectedForm: IForm = useSelector((state: RootState) => state.selectedForm);

    let video: any;

    const handleShowPhotoPreviewDialog = async (selectedFile: ISelectedFile) => {
        const storedData = await indexedDBService.getItem('File', selectedFile.name);
        if (storedData) {

            selectedFile.base64 = storedData
        }
        setSelectedFile(selectedFile)
        setShowPhotoPreviewDialog(true)
    }

    const handleClosePhotoPreviewDialog = () => {
        setShowPhotoPreviewDialog(false)
    }

    const onFileDeleteClick = (selectedFile: ISelectedFile) => {

        let selectedFiles: ISelectedFile[]

        if (currentPhotos) {
            selectedFiles = [...currentPhotos];
        }
        else {
            selectedFiles = [];
        }

        selectedFiles.forEach((file: ISelectedFile) => {
            if (file.fileGuid === selectedFile.fileGuid) {
                file.isDeleted = true
            }
        });

        setCurrentPhotos([...selectedFiles]);
    }

    const onFileDeleteCancelClick = (selectedFile: ISelectedFile) => {

        let selectedFiles: ISelectedFile[]

        if (currentPhotos) {
            selectedFiles = [...currentPhotos];
        }
        else {
            selectedFiles = [];
        }

        selectedFiles.forEach((file: ISelectedFile) => {
            if (file.fileGuid === selectedFile.fileGuid) {
                file.isDeleted = false
            }
        });

        setCurrentPhotos([...selectedFiles]);

    }

    const stopCamera = async () => {
        if (!Capacitor.isNativePlatform()) {
            video = document.querySelector("#video_" + path);
            const stream = video.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });

            video.pause();
            video.enabled = false;
            video.src = "";
            video.srcObject = null;

            setHasCameraAccess(false);
        }
    };

    const selectPhotos = async (formFieldName: string) => {
        let permissions = await Camera.checkPermissions();
        while (permissions.camera !== 'granted') {
            permissions = await Camera.requestPermissions();
        }

        const images = await Camera.pickImages({
            limit: 5,
            quality: 100,
        });

        const photoCount = images.photos.length;
        let photoCounter = 0;

        // Process each image to base64 and handle file upload
        for (const image of images.photos) {
            photoCounter = photoCounter + 1;
            let base64String = '';
            if (Capacitor.getPlatform() === 'web') {
                const response = await fetch(image.webPath!);
                const blob = await response.blob();
                base64String = await convertBlobToBase64(blob);
            } else {
                const file = await Filesystem.readFile({
                    path: image.path!,
                });

                base64String = typeof file.data === 'string' ? file.data : await convertBlobToBase64(file.data);
            }

            const filename = Date.now().toString() + supportedImageExtension;
            const selectedFile: ISelectedFile = {
                base64: 'data:' + supportedImageType + ';base64,' + base64String,
                name: filename,
                formFieldName: formFieldName,
                createdBy: user.name,
                createdDateTime: new Date(),
                isDeleted: false,
                isDraft: true,
            };

            await setFileToLocalStorage(selectedFile);
            await onUploadCloudFile(selectedFile, "Uploading " + photoCounter + " of " + photoCount + " photos...");
        }
    };

    // Helper function to convert Blob to Base64
    function convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const startCamera = async (formFieldName: string) => {
        if (!Capacitor.isNativePlatform()) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                video = document.querySelector("#video_" + path);
                if (video) {
                    video.srcObject = stream;
                    setHasCameraAccess(true);
                }
            } catch (error) {
                console.error(error);
                alert("Please allow access to your camera");
            }
        } else {
            let permissions = await Camera.checkPermissions();
            while (permissions.camera !== 'granted') {
                permissions = await Camera.requestPermissions();
            }

            let image: Photo = await Camera.getPhoto({
                quality: 100,
                resultType: CameraResultType.Base64,
            });

            if (image) {
                const filename = Date.now().toString() + supportedImageExtension;
                const selectedFile: ISelectedFile = {
                    base64: 'data:' + supportedImageType + ';base64,' + image.base64String,
                    name: filename,
                    formFieldName: formFieldName,
                    createdBy: user.name,
                    createdDateTime: new Date(),
                    isDeleted: false,
                    isDraft: true,
                };
                await setFileToLocalStorage(selectedFile);
                await onUploadCloudFile(selectedFile, "Uploading to cloud...");
            }
        }
    };

    const handleFileSelection = async ($event: any, formFieldName: string) => {

        const fileList: FileList = $event.target.files;

        await Array.from(fileList).forEach(async (file: File) => {

            const base64: string = await toBase64(file) as string;

            const selectedFile: ISelectedFile = {
                base64: base64,
                name: file.name,
                formFieldName: formFieldName,
                createdBy: user.name,
                createdDateTime: new Date(),
                isDeleted: false,
                isDraft: true,
            };

            await setFileToLocalStorage(selectedFile);
            await onUploadCloudFile(selectedFile, "Uploading to cloud...");
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const takePhoto = async (formFieldName: string) => {
        const canvas = document.createElement("canvas");
        video = document.querySelector("#video_" + path);
        if (video && canvas) {
            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const image_data_url = canvas.toDataURL(supportedImageType);

                const filename = Date.now().toString() + supportedImageExtension;

                const selectedFile: ISelectedFile = {
                    base64: image_data_url,
                    name: filename,
                    formFieldName: formFieldName,
                    createdBy: user.name,
                    createdDateTime: new Date(),
                    isDeleted: false,
                    isDraft: true,
                };

                await setFileToLocalStorage(selectedFile);
                await onUploadCloudFile(selectedFile, "Uploading to cloud...");

            } else {
                console.error('Could not get 2D context for canvas.');
            }
        }
    };

    const setFileToLocalStorage = async (selectedFile: ISelectedFile) => {
        await indexedDBService.setItem("File", selectedFile.name, selectedFile.base64);
    }

    const onUploadCloudFile = async (selectedFile: ISelectedFile, statusText: string) => {

        setUploadStatusText(statusText);
        setIsUploadingToCloud(true);

        let response = await uploadCloudFile(selectedFile);

        if (response) {
            if (response.cloudFileId) {
                selectedFile.fileId = response.cloudFileId;
                selectedFile.fileGuid = response.cloudFileGuid;
                selectedFile.base64 = '';
            }
        }

        // Use functional update to set currentPhotos and get the updated array
        setCurrentPhotos((prevPhotos) => {
            const selectedFiles = prevPhotos ? [...prevPhotos] : [];

            console.log('selectedFile: ', selectedFile);
            console.log('selectedFiles BEFORE: ', selectedFiles);

            const existingSelectedFile = selectedFiles.find(row => row.name === selectedFile.name);

            if (existingSelectedFile) {
                // Update the existing file
                Object.assign(existingSelectedFile, selectedFile);
            } else {
                // Add the new file to the beginning of the array
                selectedFiles.unshift(selectedFile);
            }

            console.log('selectedFiles AFTER: ', selectedFiles);

            // Call handleChange with the updated files
            handleChange(path, selectedFiles);

            // Set the uploading state to false after updating the state
            setIsUploadingToCloud(false);

            return selectedFiles;
        });
    };

    const uploadCloudFile = async (selectedFile: ISelectedFile) => {

        const fileDetail: IFileDetail = {
            base64: selectedFile.base64!,
            name: selectedFile.name,
            createdBy: user.name,
            formFieldName: selectedFile.formFieldName,
            createdDateTime: new Date(),
            isDeleted: false,
            isDraft: true,
        }

        const uploadCloudRequest: IUploadCloudFileRequest = {
            formId: selectedForm.formId,
            userId: user.userId,
            customerId: selectedForm.customerId,
            fileDetail: fileDetail,
        }

        if (uploadCloudRequest.formId === 0 || uploadCloudRequest.customerId === 0) {
            window.alert('form/customerid is 0. not saving photo. react refreshed. go back to form list and start over.')
            return;
        }
        if (!uploadCloudRequest.fileDetail.base64) {
            window.alert('base64 is blank. something went wrong.')
            return;
        }

        let response = await UploadCloudFile(uploadCloudRequest)

        let uploadCloudFileResponse: IUploadCloudFileResponse = {
            cloudFilesDto: null
        };

        if (response) {
            uploadCloudFileResponse.cloudFilesDto = response.cloudFilesDto;
        }

        return uploadCloudFileResponse.cloudFilesDto
    }

    const getSecondaryText = (selectedFile: ISelectedFile) => {
        const { createdBy, createdDateTime } = selectedFile;
        const localDate = new Date(createdDateTime).toLocaleDateString('en-US');
        const localTime = new Date(createdDateTime).toLocaleTimeString('en-US');
        return `Captured by ${createdBy} on ${localDate} ${localTime}`;
    };

    return (
        <div className="image-capture-container">
            <Fragment>
                <Typography variant="subtitle1" noWrap={false} style={{ wordWrap: 'break-word' }}>{label}</Typography>
                {Capacitor.isNativePlatform() && !hasCameraAccess &&
                    <div>
                        <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                            <Button onClick={() => startCamera(props.path)} component="label" variant="outlined" startIcon={<ImageIcon />}>
                                Start Camera
                            </Button>
                        </label>
                    </div>
                }
                {Capacitor.isNativePlatform() && !hasCameraAccess &&
                    <div>
                        <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                            <Button onClick={() => selectPhotos(props.path)} component="label" variant="outlined" startIcon={<ImageIcon />}>
                                Select Photo(s)
                            </Button>
                        </label>
                    </div>
                }
                {Capacitor.isNativePlatform() && hasCameraAccess &&
                    <div>
                        <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                            <Button onClick={() => takePhoto(props.path)} component="label" variant="outlined" startIcon={<ImageIcon />}>
                                Take Photo
                            </Button>
                        </label>
                    </div>
                }
                {!Capacitor.isNativePlatform() && hasCameraAccess &&
                    <div>
                        <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                            <Button onClick={stopCamera} component="label" variant="outlined" startIcon={<ImageIcon />}>
                                Stop Camera
                            </Button>
                        </label>
                    </div>
                }
                {!Capacitor.isNativePlatform() &&
                    <div>
                        <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                            <Button component="label" variant="outlined" startIcon={<AttachFileIcon />}>
                                Upload Photograph
                                <input
                                    accept={accept}
                                    style={{ display: 'none' }}
                                    id={`icon_${props.path}`}
                                    onChange={(event) => handleFileSelection(event, props.path)}
                                    type="file"
                                    multiple
                                />
                            </Button>
                        </label>
                    </div>
                }
                <div className='photo-capture-container' style={{ display: !Capacitor.isNativePlatform() && hasCameraAccess ? 'block' : 'none' }}>
                    <div className='video-preview-container'>
                        <video id={`video_${path}`} width="200" height="200" autoPlay></video>
                    </div>
                </div>
                {currentPhotos && (
                    <List>
                        {isUploadingToCloud &&
                            <ListItem>
                                <Stack direction="row" spacing={3}>
                                    <IconButton edge="end" aria-label="syncing">
                                        <SyncIcon sx={{
                                            animation: "spin 2s linear infinite",
                                            "@keyframes spin": {
                                                "0%": {
                                                    transform: "rotate(360deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(0deg)",
                                                },
                                            },
                                        }} />
                                    </IconButton>
                                </Stack>
                                <ListItemText
                                    primary={uploadStatusText}
                                    secondary={uploadStatusText}
                                />
                            </ListItem>
                        }
                        {currentPhotos.map((file: ISelectedFile) => (
                            <ListItem key={file.name} secondaryAction={
                                <Stack direction="row" spacing={3}>
                                    {!file.fileId && <IconButton edge="end" aria-label="upload" onClick={() => onUploadCloudFile(file, "Retrying upload...")}>
                                        <CloudSyncIcon />
                                    </IconButton>}
                                    {file.isDeleted ? <IconButton edge="end" aria-label="cancel" onClick={() => onFileDeleteCancelClick(file)}>
                                        <CancelIcon />
                                    </IconButton> :
                                        <IconButton edge="end" aria-label="delete" onClick={() => onFileDeleteClick(file)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </Stack>
                            }>
                                <ListItemText
                                    primary={file.name}
                                    secondary={getSecondaryText(file)}
                                    onClick={() => handleShowPhotoPreviewDialog(file)}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}

                {showPhotoPreviewDialog && <PhotoPreviewDialog
                    showDialog={showPhotoPreviewDialog}
                    selectedFile={selectedFile!}
                    selectedForm={selectedForm}
                    handleClose={handleClosePhotoPreviewDialog} />}

            </Fragment>
        </div>
    );
});

export default withJsonFormsControlProps(PhotoCaptureControl);