import { Fragment, memo, useContext, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { ReactElement } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { ControlProps } from '@jsonforms/core';
import { JsonFormsContext } from '@jsonforms/react';
import InputAdornment from '@mui/material/InputAdornment';

const CalculatedFieldControl = memo((props: ControlProps): ReactElement => {
    const {
        label,
        uischema,
        handleChange,
        path
    } = props;

    const context = useContext(JsonFormsContext);
    const formData = context.core!.data;
    const [result, setResult] = useState(0);
    const fields = uischema.options!.fields || [];
    const operation = uischema.options!.action.operation || 'sum';
    const value = uischema.options!.action.value || null;
    const format = uischema.options!.action.format || null;
    const numeratorFieldName = uischema.options!.action.numeratorFieldName || null;

    useEffect(() => {
        if (formData) {
            let calculatedResult = 0;
            let outputValue = "";

            // 1st Loop - Iterating through anything in the fields array within the field
            // 2nd Loop - Iterating through every field on the form
            fields.forEach(fieldPath => {
                Object.entries(formData).forEach(([fieldName, fieldValue]) => {
                    if (fieldName === fieldPath) {
                        if (operation === "sum") {
                            calculatedResult = calculatedResult + Number(fieldValue);
                            outputValue = calculatedResult.toString();
                        } else if (operation === "count" && value === fieldValue) {
                            calculatedResult += 1;
                            outputValue = calculatedResult.toString();
                        } else if (operation === "value" && (value === '' || value === null)) {
                            calculatedResult = Number(fieldValue)
                            outputValue = JSON.stringify(fieldValue)
                        } else if (operation === "divide") {

                            const denominatorValue = Number(fieldValue);
                            console.log('denominatorValue', denominatorValue);

                            if (denominatorValue > 0) {
                                const numeratorValue = Number(formData[numeratorFieldName]);
                                console.log('numeratorValue', numeratorValue);

                                if (numeratorValue > 0) {
                                    calculatedResult = Number((numeratorValue / denominatorValue).toFixed(2));
                                    console.log('calculatedResult', calculatedResult);

                                    if (format === "percent") {
                                        calculatedResult = calculatedResult * 100;
                                        outputValue = `${calculatedResult.toFixed(2)}%`;
                                    }
                                    else {
                                        outputValue = calculatedResult.toString();
                                    }

                                    console.log('outputValue', outputValue);
                                }
                            }

                        } else if (operation === "multiply" && value && fieldValue) {
                            const fieldValueNumber = Number(fieldValue) || 0;
                            calculatedResult = fieldValueNumber * value;

                            if (format === "percent") {
                                outputValue = `${calculatedResult}%`;
                            }
                            else {
                                outputValue = calculatedResult.toString();
                            }
                        }
                    }
                });
            });

            if (calculatedResult !== result) {
                setResult(calculatedResult);
                handleChange(path, outputValue);
            }
        }
    }, [formData, uischema.options, result, handleChange, path]); // Dependency array includes formData and uischema.options

    return (
        <div>
            <Fragment>
                {format === "percent" ?
                    <TextField
                        id="calculated-value"
                        label={label}
                        variant="standard"
                        value={result}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                    :
                    <TextField
                        id="calculated-value"
                        label={label}
                        variant="standard"
                        value={result}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                }
            </Fragment>
        </div>
    );
});

export default withJsonFormsControlProps(CalculatedFieldControl);