// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../types/reduxStore';

const initialState: IUser = {
    nickname: '',
    name: '',
    picture: '',
    updated_at: '',
    email: ''
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<IUser>) => {
            if (action.payload) {
                state.nickname = action.payload.nickname;
                state.name = action.payload.name;
                state.picture = action.payload.picture;
                state.updated_at = action.payload.updated_at;
                state.email = action.payload.email;
            } else {
                return initialState;
            }
        },
    },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
