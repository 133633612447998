import { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Typography, IconButton, Grid, createTheme, ThemeProvider, Fab } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "../common/appStyles";
import { showSnackbar } from '../services/snackbar.service';
/**
 * @remarks This React component handles the logic as well as look and feel of a
 * drag and drop/click image upload box. It also displays the image once uploaded.
 * @param - object with properties and functions required for Component to work.
 * @returns - Html component for uploading a file.
 */
function DragDropFileUpload({ onFileUpload, myFile, title }) {
    const [dragOver, setDragOver] = useState(false);
    const [fileTitle, setFileTitle] = useState<string | null>(title);
    const [filePng, setFilePng] = useState<any>();
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#10AD61'
            },
            secondary: {
                main: '#10AD61'
            },
            background: {
                default: '#ffffff'
            },
        },
        typography: {
            h6: {
                color: '#484A57'
            },
            body1: {
                color: 'black'
            }
        },
    });

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    async function blobToBase64Object(blob) {
        const base64String = await blobToBase64(blob) as string;
        const contentType = blob.type;
        const lastModified = blob.lastModified;
        const name = blob.name;

        const actualBase64String = base64String.split(',')[1];

        const base64Object = {
            FileContents: actualBase64String,
            ContentType: contentType,
            FileDownloadName: name,
            LastModified: lastModified,
            EntityTag: null,
            EnableRangeProcessing: false,
        };
        return base64Object;
    }

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = async (file) => {
        let base64File = await blobToBase64Object(file);
        const img = new Image();
        img.src = "data:image/jpeg;base64," + base64File.FileContents;
        img.onload = () => {
            onFileUpload(base64File);
            setFilePng(base64File);
            setFileTitle(base64File.FileDownloadName);
        };

    }

    const handleclose = () => {
        onFileUpload("");
        setFilePng("");
        setFileTitle("");
    }


    const handleChange = useCallback(
        (event) => {

            const files = event.target.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        [filePng]
    );

    useEffect(() => {
        if (myFile) {
            if (!myFile.FileContents) {
                const base64Object = {
                    FileContents: myFile,
                    ContentType: "image/jpeg",
                    FileDownloadName: title,
                    LastModified: "",
                    EntityTag: null,
                    EnableRangeProcessing: false,
                };
                onFileUpload(base64Object);
                setFilePng(base64Object);
            }
        }
    }, [myFile]
    )


    return (
        <Box>
            <ThemeProvider theme={theme}>
                <Paper
                    variant="outlined"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{
                        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                        textAlign: 'center',
                        padding: 10,
                        cursor: 'pointer',
                        background: dragOver ? '#eee' : '#fafafa',
                        position: 'relative',
                    }}
                >
                    {fileTitle && filePng ? (
                        <Grid container justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} alignItems={"center"} justifyContent={"center"} rowSpacing={3}>
                                    <Grid item xs={12}>
                                        <div style={{ width: "728px", height: "90px" }}
                                            className={`${classes.hoverableItem} ${classes.hoverableItemHover}`}>
                                            <span>
                                                <img
                                                    src={`data:${filePng.ContentType};base64,${filePng.FileContents}`}
                                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                />
                                            </span>
                                            <div className={classes.backdrop}>
                                                <Fab color='primary' onClick={handleclose}>
                                                    <CloseIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) :
                        <>
                            <input
                                accept="image/png"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleChange} />
                            <label htmlFor="raised-button-file">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <CloudUploadIcon style={{ fontSize: 60 }} />
                                    </IconButton>
                                    <Typography>Drag and drop images here or click to select an image</Typography>
                                </Box>
                            </label>
                        </>
                    }
                </Paper>
            </ThemeProvider>
        </Box>
    );
}
export default DragDropFileUpload;