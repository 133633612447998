import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  FormControl,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  // List,
  // ListSubheader,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useStyles } from "../common/appStyles";
import { SaveDropboxAccessToken } from "../services/customer.service";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { showSnackbar } from "../services/snackbar.service";
import { appConfig } from '../services/config.service';
import { Browser } from '@capacitor/browser';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import indexedDBService from "../services/indexdb-service";
import { GetUser } from "../services/user-service";
import { LinkUserAccount, SaveLinkedAccountUserIds } from '../services/manage-service';
import { updateUserResponse } from "../store/userResponseSlice";
import { IUserResponse } from "../types/reduxStore";
import { App as CapApp } from '@capacitor/app';
import { Capacitor, } from '@capacitor/core';

const MyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = !useMediaQuery('(min-width:600px)');
  const user = useSelector((state: RootState) => state.userDetail);

  //const [loaded, setLoaded] = useState(false);
  const [linkedToDropbox, setLinkedToDropbox] = useState(false);
  // const [linkedAccountToGoogle, setLinkedAccountToGoogle] = useState(false);
  // const [linkedAccountToDropbox, setLinkedAccountToDropbox] = useState(false);
  // const [linkedAccountToMicrosoft, setLinkedAccountToMicrosoft] = useState(false);


  // const getInitials = () => {
  //   return user.name?.split('.').map((n) => n[0]).join('').toUpperCase();
  // };

  //const getRedirectUri = () => {
  //  return document.location.origin + "/profile";
  //}

  //functions to generate URL's to link accounts with social providers
  // const generateLinkToLinkGoogleAccount = () => {
  //   const url = appConfig.GOOGLE_LOGIN + "&redirect_uri=" + encodeURIComponent(getRedirectUri()) + "&state=google-oauth2";
  //   document.location.href = url;
  // }

  // const generateLinkToLinkDropboxAccount = () => {
  //   const url = appConfig.DROPBOX_LOGIN + "&redirect_uri=" + encodeURIComponent(getRedirectUri()) + "&state=dropbox";
  //   document.location.href = url;
  // }

  // const generateLinkToLinkMicrosoftAccount = () => {
  //   const url = appConfig.MICROSOFT_LOGIN + "&redirect_uri=" + encodeURIComponent(getRedirectUri()) + "&state=windowslive";
  //   document.location.href = url;
  // }

  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const getAccessTokenFromHash = () => {
    let hash = window.location.hash;
    let access_token = hash.substring(hash.indexOf("access_token="), hash.indexOf('&')).split('=')[1];
    return access_token;
  }

  const getStateFromHash = () => {
    let hash = window.location.hash;
    let access_token = hash.substring(hash.indexOf("state=")).split('=')[1];
    return access_token;
  }

  const generateAccessCode = async () => {
    const url = appConfig.DROPBOX_ACCESSCODEURL;
    //document.location.href = url;

    console.log('opening this URL: ', url)

    await Browser.open({
      url,
      windowName: "_self"
    });

  };

  // const setLinkedAccount = (state) => {
  //   switch (state) {
  //     case "google-oauth2":
  //       setLinkedAccountToGoogle(true)
  //       break;
  //     case "dropbox":
  //       setLinkedAccountToDropbox(true);
  //       break;
  //     case "windowslive":
  //       setLinkedAccountToMicrosoft(true);
  //   }
  // }

  // const setUnlinkedAccount = (state) => {
  //   switch (state) {
  //     case "google-oauth2":
  //       setLinkedAccountToGoogle(false)
  //       break;
  //     case "dropbox":
  //       setLinkedAccountToDropbox(false);
  //       break;
  //     case "windowslive":
  //       setLinkedAccountToMicrosoft(false);
  //   }
  // }

  const getAccountUserIds = (state, userId) => {
    const AccountUserIds = {
      user_id: user.userId,
      // linkedGoogleUserId: user.linkedGoogleUserId,
      // linkedDropboxUserId: user.linkedDropboxUserId,
      // linkedMicrosoftUserId: user.linkedMicrosoftUserId
    }
    // switch (state) {
    //   case "google-oauth2":
    //     AccountUserIds.linkedGoogleUserId = userId;
    //     break;
    //   case "dropbox":
    //     AccountUserIds.linkedDropboxUserId = userId;
    //     break;
    //   case "windowslive":
    //     AccountUserIds.linkedMicrosoftUserId = userId;
    // }
    return AccountUserIds;
  }

  // const setAccountIdsNull = (state) => {
  //   const AccountUserIds = {
  //     user_id: user.userId,
  //     linkedGoogleUserId: user.linkedGoogleUserId,
  //     linkedDropboxUserId: user.linkedDropboxUserId,
  //     linkedMicrosoftUserId: user.linkedMicrosoftUserId
  //   }
  //   switch (state) {
  //     case "google-oauth2":
  //       AccountUserIds.linkedGoogleUserId = null;
  //       break;
  //     case "dropbox":
  //       AccountUserIds.linkedDropboxUserId = null;
  //       break;
  //     case "windowslive":
  //       AccountUserIds.linkedMicrosoftUserId = null;
  //   }
  //   return AccountUserIds;
  // }

  const getUserAndResetStore = () => {
    GetUser()
      .then(async (userResp: IUserResponse) => {
        localStorage.setItem("user", btoa(JSON.stringify(userResp.userDetail)));
        dispatch(updateUserResponse(userResp));
        await indexedDBService.setItem('User', 'userResp', userResp);
      })
      .catch(error => {
        console.error('Error getUserAndResetStore:', error);
      });
  }

  const LinkAccount = async (state, token) => {
    try {
      var decodedToken = parseJwt(token);
      const request = {
        primary_id: user.auth0UserId,
        secondary_id: decodedToken.sub,
        provider: state
      }
      await LinkUserAccount(request);
      //setLinkedAccount(state);
      SaveLinkedAccountUserIds(getAccountUserIds(state, decodedToken.sub));
      getUserAndResetStore();
    } catch (error) {
      console.error(error);
      getUserAndResetStore();
      // switch (state) {
      //   case "google-oauth2":
      //     setLinkedAccountToGoogle(false)
      //     break;
      //   case "dropbox":
      //     setLinkedAccountToDropbox(false);
      //     break;
      //   case "windowslive":
      //     setLinkedAccountToMicrosoft(false);
      // }
    }
  }

  // const UnlinkAccount = async (e: React.MouseEvent<HTMLButtonElement>, linkedId: Nullable<string>) => {
  //   var state = linkedId?.split("|")[0]
  //   var linkedUserId = linkedId?.split("|")[1]
  //   try {
  //     const request = {
  //       primary_id: user.auth0UserId,
  //       secondary_id: linkedUserId,
  //       provider: state
  //     }
  //     await UnlinkUserAccount(request);
  //     setUnlinkedAccount(state);
  //     SaveLinkedAccountUserIds(setAccountIdsNull(state));
  //     getUserAndResetStore();
  //   }
  //   catch (error) {
  //     console.error(error);
  //     getUserAndResetStore();
  //     switch (state) {
  //       case "google-oauth2":
  //         setLinkedAccountToGoogle(false)
  //         break;
  //       case "dropbox":
  //         setLinkedAccountToDropbox(false);
  //         break;
  //       case "windowslive":
  //         setLinkedAccountToMicrosoft(false);
  //     }
  //   }
  // }

  const saveAccessCode = async (code: string) => {
    try {
      const request = {
        customerId: user.customerId,
        userId: user.userId,
        accessCode: code,
        redirectUri: appConfig.DROPBOX_REDIRECTURL
      }
      await SaveDropboxAccessToken(request);
      getUserAndResetStore();
      setLinkedToDropbox(true);
      showSnackbar('Connected to Dropbox successfully', 'success', 5000);
    } catch (error) {
      getUserAndResetStore();
      setLinkedToDropbox(false);
    }
  };

  const disconnectFromDropbox = async () => {
    const request = {
      customerId: user.customerId,
      userId: user.userId,
      accessCode: "",
      redirectUri: ""
    }
    await SaveDropboxAccessToken(request);
    getUserAndResetStore();
    setLinkedToDropbox(false);
    showSnackbar('Disconnected from Dropbox', 'success', 5000);
  }

  // Perform any setup that requires access to the DOM
  useEffect(() => {
    console.log('Profile useEffect')
    if (!Capacitor.isNativePlatform()) {
      if (window.location.search) {
        let urlParams = new URLSearchParams(window.location.search);
        let code: string = urlParams.get('code') + "";
        code = decodeURIComponent(code);
        saveAccessCode(code);
        window.history.replaceState(null, '', window.location.pathname);
      }
      else if (getAccessTokenFromHash() != null) {
        let access_token = getAccessTokenFromHash();
        LinkAccount(getStateFromHash(), access_token);
        window.history.replaceState(null, '', window.location.pathname);
      }
      setLinkedToDropbox(user.linkedToDropbox);
      // setLinkedAccountToGoogle(user.linkedToGoogleAccount);
      // setLinkedAccountToDropbox(user.linkedToDropboxAccount);
      // setLinkedAccountToMicrosoft(user.linkedToMicrosoftAccount);
    }
    else {
      console.log('Adding DropBox Listener')
      CapApp.addListener('appUrlOpen', async ({ url }) => {
        console.log('url: ', url)
        if (url.includes('profile') && (url.includes('code') || url.includes('error'))) {
          console.log('FOUND url ', url)
          let urlParams = new URLSearchParams(url);
          let code: string = urlParams.get('code') + "";
          code = decodeURIComponent(code);
          console.log('FOUND code ', code)
          await saveAccessCode(code);
          await Browser.close()
        }

      });
    }
  }, []);






  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} className={classes.shoFormContainer}>
      <Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ m: 2 }}>

          {!linkedToDropbox && <FormControl fullWidth sx={{ padding: 2 }}>
            <h1 className={classes.cardTitle + ' ' + classes.textCenter + (isMobile ? ' ' + classes.cardTitleMobileView : '')}>Link to Dropbox</h1>
            <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={generateAccessCode} variant="contained" className={classes.btnWidth}>
                Connect to Dropbox
              </Button>
            </div>


            {/* <Accordion sx={{ marginTop: 4 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How to Connect Dropbox?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    subheader={<ListSubheader>Follow below Steps</ListSubheader>}
                  >
                    <ListItem>
                      <ListItemIcon>
                        <RadioButtonCheckedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Click Generate Access Code" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <RadioButtonCheckedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Allow Permissions and copy Access Code from Dropbox Account" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <RadioButtonCheckedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Paste Access Code in Sho Forms and click on Save Access Code button" />
                    </ListItem>
                  </List>
                </Typography>
              </AccordionDetails>
            </Accordion> */}
            <Accordion sx={{ marginTop: 4 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Benifits of Linking with Dropbox?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you wish to store Form Preview PDF file to your dropbox account then you can connect your Dropbox account with Sho Forms
                </Typography>
              </AccordionDetails>
            </Accordion>

          </FormControl>}
          {linkedToDropbox &&
            <FormControl fullWidth sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 4 }}>
              <h3 className={classes.cardInfoText}>Your Account is connected with your Dropbox Account</h3>
              <div className={classes.buttonsContainer}>
                <Button onClick={disconnectFromDropbox} variant="contained" className={classes.btnWidth}>
                  Disconnect from Dropbox
                </Button>
              </div>
            </FormControl>
          }
        </Paper>
      </Grid>
      {/*<Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ m: 2 }}>

          <FormControl fullWidth sx={{ padding: 2 }}>
            <h1 className={classes.cardTitle + ' ' + classes.textCenter + (isMobile ? ' ' + classes.cardTitleMobileView : '')}>Link Account</h1>
            {!linkedAccountToGoogle && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={generateLinkToLinkGoogleAccount} variant="contained" className={classes.btnWidth}>
                Link Google Account
              </Button>
            </div>}
            {linkedAccountToGoogle && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={(e) => UnlinkAccount(e, user.linkedGoogleUserId)} variant="contained" className={classes.btnWidth}>
                Unlink Google Account
              </Button>
            </div>}
            {!linkedAccountToDropbox && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={generateLinkToLinkDropboxAccount} variant="contained" className={classes.btnWidth}>
                Link Dropbox Account
              </Button>
            </div>}
            {linkedAccountToDropbox && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={(e) => UnlinkAccount(e, user.linkedDropboxUserId)} variant="contained" className={classes.btnWidth}>
                Unlink Dropbox Account
              </Button>
            </div>}
            {!linkedAccountToMicrosoft && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={generateLinkToLinkMicrosoftAccount} variant="contained" className={classes.btnWidth}>
                Link Microsoft Account
              </Button>
            </div>}
            {linkedAccountToMicrosoft && <div className={classes.buttonsContainer + (isMobile ? ' ' + classes.buttonsContainerMobileView : '')}>
              <Button onClick={(e) => UnlinkAccount(e, user.linkedMicrosoftUserId)} variant="contained" className={classes.btnWidth}>
                Unlink Microsoft Account
              </Button>
            </div>}

            <Accordion sx={{ marginTop: 4 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Benifits of Linking your account?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  It allows for easier Login to Sho Forms with your prefered platform
                </Typography>
              </AccordionDetails>
            </Accordion>

          </FormControl>

        </Paper>
      </Grid> */}
    </Grid>
  );
};

export default MyProfile;

