import ShoFormsClient from '../httpClient/ShoFormsClient';
import { IAddUpdateFormGroupRequest, IDeleteFormGroupRequest, IFormGroup, IFormGroupListRequest, IFormGroupListResponse, IUserFormGroup, IUserFormGroupListRequest, IUserFormGroupListResponse } from '../types/forms';
import indexedDBService from './indexdb-service';
import { showSnackbar } from './snackbar.service';
import { Network } from '@capacitor/network';

export const GetFormGroupList = async (request: IFormGroupListRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IFormGroupListResponse = (await instance.post('/FormGroups/GetFormGroupListV2', request)).data;
        // Store response to index db
        await indexedDBService.setItem('General', 'GetFormGroupList', response.formGroupList);
        const formGroupList: IFormGroup[] = response.formGroupList;
        return formGroupList;
    } catch (error) {

        const status = await Network.getStatus();

        if (!navigator.onLine || !status.connected) {
            const indexStorageData = await indexedDBService.getItem('General', 'GetFormGroupList');
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};

export const GetUserFormGroupList = async (request: IUserFormGroupListRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IUserFormGroupListResponse = (await instance.post('/FormGroups/GetUserFormGroupList', request)).data;
        // Store response to index db
        await indexedDBService.setItem('General', 'GetUserFormGroupList', response.userFormGroupList);
        const userFormGroupList: IUserFormGroup[] = response.userFormGroupList;
        return userFormGroupList;
    } catch (error) {

        const status = await Network.getStatus();

        if (!navigator.onLine || !status.connected) {
            const indexStorageData = await indexedDBService.getItem('General', 'GetUserFormGroupList');
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};

export const AddUpdateFormGroup = async (request: IAddUpdateFormGroupRequest) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post('/FormGroups/AddUpdateFormGroup', request);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const DeleteFormGroup = async (request: IDeleteFormGroupRequest) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post('/FormGroups/DeleteFormGroup', request);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetFormGroupMasters = async (request) => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.post('/FormGroups/GetFormGroupMasters', request);
        return response.data;
    } catch (error) {
        showSnackbar("Error while getting form group master", "error");
    }
};
