import React, { useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Logo from "../../components/main-layout/Logo"

const DropBoxCallbackMobileRedirect: React.FunctionComponent = () => {

    useEffect(() => {

        let urlParams = new URLSearchParams(window.location.search);
        let code: string = urlParams.get('code') + "";
        let mobileUrlScheme: string = urlParams.get('mobileUrlScheme') + "";
        code = decodeURIComponent(code);
        mobileUrlScheme = decodeURIComponent(mobileUrlScheme);

        const link = document.createElement('a');
        link.href = mobileUrlScheme + "://profile?callingSystem=dropbox&code=" + code;
        link.style.display = 'none'; // Hide the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    return (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Card sx={{ maxWidth: 500 }}>
                        <Logo />

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Redirecting...
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                We are redirecting you back to ShoForms...
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DropBoxCallbackMobileRedirect;
