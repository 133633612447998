import { useEffect, useReducer, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { LockReset } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { getCustomerList } from '../services/customer.service';
import { ICustomer } from '../types/customer';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { RoleEnum } from '../types/enums';

const initialState = {
    name: '',
    email: '',
    roleId: 2,
    isActive: false,
    auth0UserId: '',
    isValid: {
        name: false,
        email: false,
    },
    touched: {
        name: false,
        email: false,
    },
    customerId: 0,
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'SET_VALID':
            return { ...state, isValid: { ...state.isValid, [action.field]: action.isValid } };
        case 'SET_TOUCHED':
            return { ...state, touched: { ...state.touched, [action.field]: true } };
        default:
            throw new Error();
    }
}

function isValidName(name) {
    return name.trim().length > 0;
}

function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function UserDialog(props) {
    const { showDialog, titleText, selectedUser, handleClose, handleSave, handleChangePassword } = props;
    const userId = selectedUser.userId;
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.userDetail);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        name: selectedUser.name,
        email: selectedUser.email,
        roleId: selectedUser.roleId || initialState.roleId,
        customerId: selectedUser.customerId || initialState.customerId,
        isActive: selectedUser.isActive,
        auth0UserId: selectedUser.auth0UserId
    });

    const { name, email, roleId, customerId, isActive, auth0UserId, isValid, touched } = state;

    const [customers, setCustomers] = useState<ICustomer[]>([]);

    useEffect(() => {
        if (selectedUser.name) {
            validateField('name', selectedUser.name);
        }
        if (selectedUser.email) {
            validateField('email', selectedUser.email);
        }

        const fetchData = async () => {
            try {
                const request = {
                    userId: user.userId
                }
                const customerList: ICustomer[] = await getCustomerList(request) ?? [];
                setCustomers(customerList);
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };
        fetchData();
    }, [selectedUser]);

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
        validateField(field, value);
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };

    const validateField = (field, value) => {
        let isValid = false;
        switch (field) {
            case 'name':
                isValid = isValidName(value);
                break;
            case 'email':
                isValid = isValidEmail(value);
                break;
            default:
                break;
        }

        dispatch({ type: 'SET_VALID', field, isValid });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const updatedUser = { userId: selectedUser.userId, name, email, roleId, customerId, isActive, auth0UserId, createdById: user.userId };
        handleSave(updatedUser);
    };

    const handleResetPassword = (event: any) => {
        var request = { "email": email }
        handleChangePassword(request);
    }

    const isFormValid = isValid.name && isValid.email;

    //const [isVisible, setIsVisible] = React.useState(false);

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {titleText}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} className={classes.shoFormContainer}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Name"
                                value={name}
                                onChange={(event) => setField('name', event.target.value)}
                                onBlur={() => setTouched('name')}
                                error={!isValid.name && touched.name}
                                helperText={!isValid.name && touched.name ? "Please enter a valid name" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Email"
                                value={email}
                                onChange={(event) => setField('email', event.target.value)}
                                onBlur={() => setTouched('email')}
                                error={!isValid.email && touched.email}
                                helperText={!isValid.email && touched.email ? "Please enter a valid email address" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select
                                labelId="role-label"
                                value={roleId}
                                label="Role"
                                onChange={(event) => setField('roleId', event.target.value)}
                            >
                                {RoleEnum.ShoAdmin >= user.roleId && <MenuItem value={RoleEnum.ShoAdmin}>
                                    Sho Admin
                                </MenuItem>}
                                {RoleEnum.ShoUser >= user.roleId && <MenuItem value={RoleEnum.ShoUser}>
                                    Sho User
                                </MenuItem>}
                                {RoleEnum.CustomerAdmin >= user.roleId && <MenuItem value={RoleEnum.CustomerAdmin}>
                                    Customer Admin
                                </MenuItem>}
                                {RoleEnum.CustomerUser >= user.roleId && <MenuItem value={RoleEnum.CustomerUser}>
                                    Customer User
                                </MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    {roleId === RoleEnum.CustomerAdmin || roleId === RoleEnum.CustomerUser ? (
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Customer</InputLabel>
                                <Select
                                    labelId="role-label"
                                    value={customerId}
                                    label="Customer"
                                    onChange={(event) => setField('customerId', event.target.value)}
                                >
                                    {customers.map((option: ICustomer) => (
                                        <MenuItem key={option.customerId} value={option.customerId}>
                                            {option.customerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={isActive}
                                    checked={isActive}
                                    color="primary"
                                    onChange={(event) => setField('isActive', !isActive)}
                                />}
                            label="Status"
                            labelPlacement="top"
                        />
                    </Grid>
                    <Grid container alignItems={"center"} className={classes.shoFormContainer} >
                        <Grid item xs={12} md={1.2}  >
                            <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
                                {userId > 0 ? "Update User" : "Create User"}
                            </Button>
                        </Grid>
                        {userId > 0 ? (
                            <Grid item xs={12} md={1.5}  >
                                <Button onClick={handleResetPassword} className="sho-button pd5" color='primary' size="small" variant='outlined'>
                                    <LockReset className="fs20 mr6 sho-icon" color='primary' />Reset Password
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
