import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import EditIcon from '@mui/icons-material/Edit';

import { IUserDetail, IUserListRequest } from '../types/forms';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { GetUserList } from '../services/manage-service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';


const UserList = ({ onEditUser }) => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const user = useSelector((state: RootState) => state.userDetail);
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.Users];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<IUserDetail[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getUserList = () => {
        const request: IUserListRequest = {
            customerId: user.customerId,
            userId: user.userId,
        };

        GetUserList(request)
            .then(response => {
                setRows(response.userList);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // Perform any setup that requires access to the DOM
    useEffect(() => {
        getUserList();
    }, []);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
            >
                <Grid item sm={12} className='minWidth'>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell key="name">Name</TableCell>
                                        <TableCell key="email">Email</TableCell>
                                        <TableCell key="roleName">Role</TableCell>
                                        <TableCell key="isActive">Active</TableCell>
                                        {currentPage?.canEdit && <TableCell key="actions" align="center">Actions</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row: IUserDetail) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.userId}>
                                                    <TableCell key="name">{row.name}</TableCell>
                                                    <TableCell key="email">{row.email}</TableCell>
                                                    <TableCell key="roleName">{row.roleName}</TableCell>
                                                    <TableCell key="isActive">{row.isActive ? "Yes" : "No"}</TableCell>
                                                    <TableCell key="actions" align="center">
                                                        {currentPage?.canEdit && isMobile && <EditIcon onClick={e => onEditUser(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                        {currentPage?.canEdit && !isMobile && <Button onClick={e => onEditUser(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                            <EditIcon className="fs20 mr6 sho-icon" color='primary' /> Edit User
                                                        </Button>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default UserList;
