import ShoFormsClient from '../httpClient/ShoFormsClient';
import { IRolePageAccessRight } from '../types/manage';



export const GetUserList = async (request) => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.post('/Manage/GetUserList', request);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetRolePageAccessRights = async () => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.get('/Manage/GetRolePageAccessRights');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveRolePageAccessRight = async (rolePageAccessRightList: IRolePageAccessRight[]) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post('/Manage/SaveRolePageAccessRight', rolePageAccessRightList);
        return response.data as boolean;
    } catch (error) {
        throw error;
    }
};

export const saveUser = async (request) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post('/Manage/SaveUser', request);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const ChangeUserPassword = async (email) => {
    try {
        const request = email;
        const instance = ShoFormsClient();
        const response = await instance.post('/Auth0User/ChangePassword', request);
        return response.data;
    } catch (error) {
        throw error
    }
}


export const LinkUserAccount = async (request) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post("/Auth0User/LinkAccount", request);
        return response.data;
    }
    catch (error) {
        return error;
    }
}

export const UnlinkUserAccount = async (request) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post("/Auth0User/UnlinkAccount", request);
        return response.data;
    }
    catch (error) {
        return error;
    }
}

export const SaveLinkedAccountUserIds = async (request) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post("/Manage/SaveLinkedAccountUserIds", request);
        return response.data;
    }
    catch (error) {
        return error;
    }

}