import { useEffect, useReducer, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { IUserOption, IFormOption } from '../types/form-groups';
import { GetFormGroupMasters } from '../services/form-group-service';
import { IAddUpdateFormGroupRequest, IDeleteFormGroupRequest } from '../types/forms';
import { ICustomer } from '../types/customer';
import { getCustomerList } from '../services/customer.service';

const initialState = {
    formGroupId: 0,
    formGroupName: "",
    formIdList: [],
    userIdList: [],
    customerId: -1,

    isValid: {
        name: false,
        email: false,
    },
    touched: {
        name: false,
        email: false,
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'SET_VALID':
            return { ...state, isValid: { ...state.isValid, [action.field]: action.isValid } };
        case 'SET_TOUCHED':
            return { ...state, touched: { ...state.touched, [action.field]: true } };
        default:
            throw new Error();
    }
}

export default function CreateFormGroupDialog(props) {
    const { showDialog, titleText, selectedFormGroup, handleClose, handleSave, handleDelete } = props;
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.userDetail);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        formGroupId: selectedFormGroup.formGroupId,
        formGroupName: selectedFormGroup.formGroupName,
        customerId: selectedFormGroup.customerId,
        isReadOnlyFormGroup: selectedFormGroup.isReadOnlyFormGroup,
        formIdList: selectedFormGroup.formIdList ? selectedFormGroup.formIdList : [],
        userIdList: selectedFormGroup.userIdList ? selectedFormGroup.userIdList : [],
    });

    let { formGroupName, formGroupId, customerId, formIdList, userIdList, isReadOnlyFormGroup, isValid, touched } = state;

    const [users, setUsers] = useState<IUserOption[]>([]);
    const [forms, setForms] = useState<IFormOption[]>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState<number>(-1);
    const [isCustomerListLoaded, setIsCustomerListLoaded] = useState(false);

    const getFormGroupMasters = (customerId: number) => {
        const request = { customerId: customerId };

        GetFormGroupMasters(request)
            .then(response => {
                // Set the users and forms from the response
                setUsers(response.users);
                setForms(response.forms);

                // Extract the IDs from response.users and response.forms
                const responseUserIds = response.users.map(user => user.userId);
                const responseFormIds = response.forms.map(form => form.formId);

                // Filter userIdList to only include IDs present in response.users
                const filteredUserIdList = userIdList.filter(id => responseUserIds.includes(id));

                // Filter formIdList to only include IDs present in response.forms
                const filteredFormIdList = formIdList.filter(id => responseFormIds.includes(id));

                // Dispatch actions to update the state with the filtered lists
                dispatch({ type: 'SET_FIELD', field: 'userIdList', value: filteredUserIdList });
                dispatch({ type: 'SET_FIELD', field: 'formIdList', value: filteredFormIdList });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
        validateField(field, value);
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };

    const validateField = (field, value) => {
        let isValid = false;
        switch (field) {
            case 'formGroupName':
                isValid = formGroupName?.trim().length > 0;
                break;
            default:
                break;
        }
        dispatch({ type: 'SET_VALID', field, isValid });
    };

    const handleCustomerChange = (event: any) => {
        setField('customerId', event.target.value)
        setSelectedCustomerId(event.target.value);
    };

    const handleFormListChange = (event: any) => {
        console.log('handleFormListChange', event.target.value)
        setField('formIdList', event.target.value)
        //TODO: If the form list does not have any of the pre-selected values in it, remove them as selected
        console.log('formIdList', formIdList)
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        let addUpdateFormGroupRequest: IAddUpdateFormGroupRequest = {
            formGroupId: formGroupId,
            formGroupName: formGroupName,
            isReadOnlyFormGroup: isReadOnlyFormGroup,
            customerId: customerId,
            formIdList: formIdList,
            userIdList: userIdList
        }

        handleSave(addUpdateFormGroupRequest);
    };

    const handleDeleteFormGroup = () => {

        let deleteFormGroupRequest: IDeleteFormGroupRequest = {
            formGroupId: formGroupId,
        }

        handleDelete(deleteFormGroupRequest);
    };

    const isFormValid = isValid.formGroupName;

    useEffect(() => {

        if (formGroupName) {
            validateField('formGroupName', formGroupName);
        }

        const fetchData = async () => {
            try {

                const request = {
                    userId: user.userId
                }
                const customerList: ICustomer[] = await getCustomerList(request) ?? [];
                setCustomers(customerList);

                if (customerId) {
                    setSelectedCustomerId(customerId)
                    getFormGroupMasters(customerId)
                }

                setIsCustomerListLoaded(true);

            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (isCustomerListLoaded && selectedCustomerId !== -1) {
            getFormGroupMasters(selectedCustomerId);
        }
    }, [selectedCustomerId, isCustomerListLoaded]);

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {titleText}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} className={classes.shoFormContainer}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Group Name"
                                value={formGroupName}
                                onChange={(event) => setField('formGroupName', event.target.value)}
                                onBlur={() => setTouched('formGroupName')}
                                error={!isValid.formGroupName && touched.formGroupName}
                                helperText={!isValid.formGroupName && touched.formGroupName ? "Please enter a valid name" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Assign Customer</InputLabel>
                            <Select
                                labelId="role-label"
                                value={customerId}
                                multiple={false}
                                label="Select Customer"
                                onChange={handleCustomerChange}
                            >
                                {customers.map((option: ICustomer) => (
                                    <MenuItem key={option.customerId} value={option.customerId}>
                                        {option.customerName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Assign Form(s)</InputLabel>
                            <Select
                                labelId="role-label"
                                value={formIdList}
                                multiple={true}
                                label="Select Form(s)"
                                onChange={handleFormListChange}
                            >
                                {forms.map((option: IFormOption) => (
                                    <MenuItem key={option.formId} value={option.formId}>
                                        {option.formName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Assign User(s)</InputLabel>
                            <Select
                                labelId="role-label"
                                value={userIdList}
                                multiple={true}
                                label="Select Users(s)"
                                onChange={(event) => setField('userIdList', event.target.value)}
                            >
                                {users.map((option: IUserOption) => (
                                    <MenuItem key={option.userId} value={option.userId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={isReadOnlyFormGroup}
                                    checked={isReadOnlyFormGroup}
                                    color="primary"
                                    onChange={(event) => setField('isReadOnlyFormGroup', !isReadOnlyFormGroup)}
                                />}
                            label="Read Only Form Group?"
                            labelPlacement="top"
                        />
                    </Grid>

                    <Grid item xs={12} justifyContent={'center'}>
                        <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
                            {formGroupId > 0 ? "Update Form Group" : "Create Form Group"}
                        </Button>
                    </Grid>
                    {formGroupId > 0 && <Grid item xs={12} justifyContent={'center'}>
                        <Button onClick={handleDeleteFormGroup} variant="contained" color="error">
                            Delete Form Group
                        </Button>
                    </Grid>}
                </Grid>
            </Dialog>
        </div>
    );
}
