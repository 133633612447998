import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutComponent from './components/AppLayout';
import MyForms from './pages/MyForms';
import FormBuilder from './pages/FormBuilder';
import Users from './pages/Users';
import FormGroups from './pages/FormGroups';
import RolesManagement from './pages/RolesManagement/RolesManagement';
import Login from './pages/Login/Login';
import Customers from './pages/Customers';
import MyProfile from './pages/MyProfile';
import TempFileDownload from './pages/TempFileDownload';
import withProtectedRoute from './pages/Login/ProtectedRoute';
import { useNetworkSync } from './hooks/useNetworkSync';
import ViewFormDataList from './components/ViewFormDataList';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { RootState } from './store/store';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { IBranding } from './types/reduxStore';
import HomePage from './pages/HomePage';
import DropBoxCallbackMobileRedirect from './pages/Login/DropBoxCallbackMobileRedirect';

export interface IApplicationProps { }

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  useNetworkSync();

  const ProtectedLayoutComponent = withProtectedRoute(LayoutComponent);
  const ProtectedHomePage = withProtectedRoute(HomePage);
  const ProtectedMyForms = withProtectedRoute(MyForms);
  const ProtectedFormBuilder = withProtectedRoute(FormBuilder);
  const ProtectedUsers = withProtectedRoute(Users);
  const ProtectedFormGroups = withProtectedRoute(FormGroups);
  const ProtectedRolesManagement = withProtectedRoute(RolesManagement);
  const ProtectedViewFormDataList = withProtectedRoute(ViewFormDataList);
  const ProtectedMyProfile = withProtectedRoute(MyProfile);
  const ProtectedTempFileDownload = withProtectedRoute(TempFileDownload);
  const ProtectedCustomers = withProtectedRoute(Customers);

  const branding: IBranding = useSelector((state: RootState) => state.branding);

  const { handleRedirectCallback, isLoading, error } = useAuth0();
  const navigate = useNavigate();
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false)

  const [primaryColor, setPrimaryColor] = useState(branding.primaryColor)

  const theme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: primaryColor
      }
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '0.8em 0',
          },
        }
      },
    },
  }), [branding]);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
        navigate('/form-list')
      }
      await Browser.close()
    });

    window.addEventListener('colorStorage', () => {
      setPrimaryColor(branding.primaryColor)
      setShouldUpdate(true)
      window.location.reload()
    })
  }, [handleRedirectCallback]);


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<ProtectedLayoutComponent />} >
          <Route index element={<ProtectedHomePage />} /> {/* Sets the default sub-page */}
          <Route path="form-list" element={<ProtectedMyForms />} />
          <Route path="form-entries/:formId/:isReadOnlyMode" element={<ProtectedViewFormDataList />} />
          <Route path="form-builder" element={<ProtectedFormBuilder />} />
          <Route path="file-download" element={<ProtectedTempFileDownload />} />
          <Route path="form-builder/:formId" element={<ProtectedFormBuilder />} />
          <Route path="file-download" element={<ProtectedTempFileDownload />} />
          <Route path="users" element={<ProtectedUsers />} />
          <Route path="form-groups" element={<ProtectedFormGroups />} />
          <Route path="roles" element={<ProtectedRolesManagement />} />
          <Route path="profile" element={<ProtectedMyProfile />} />'
          <Route path="customers" element={<ProtectedCustomers />} />'
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/dropBoxCallbackMobileRedirect" element={<DropBoxCallbackMobileRedirect />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Application;

