import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { showSnackbar } from '../services/snackbar.service';
import { IFormDataFileRequest, IFormDataFileResponse, ISelectedFile, IForm } from '../types/forms'
import { GetFormDataFile } from '../services/form-service';
import { RootState } from '../store/store';
import { IUserDetail } from '../types/reduxStore';
import indexedDBService from '../services/indexdb-service';

export interface PhotoPreviewDialogProps {
    showDialog: any;
    handleClose: any;
    selectedForm: IForm;
    selectedFile: ISelectedFile;
}

export default function PhotoPreviewDialog(props: PhotoPreviewDialogProps) {

    const showDialog = props.showDialog;
    const handleClose = props.handleClose;
    const selectedForm = props.selectedForm;
    const selectedFile = props.selectedFile;

    const [formDataFile, setFormDataFile] = useState<IFormDataFileResponse>();

    const user: IUserDetail = useSelector((state: RootState) => state.userDetail);

    useEffect(() => {
        if (selectedFile.base64) {

            const formDataFileResponse: IFormDataFileResponse = {
                cloudFileId: 0,
                cloudFileGuid: '',
                fileName: selectedFile.name,
                fileSize: '',
                fileType: '',
                customerId: -1,
                formId: -1,
                formDataId: -1,
                createdDate: selectedFile.createdDateTime,
                createdBy: selectedFile.createdBy,
                modifiedDate: undefined,
                modifiedBy: '',
                isDeleted: false,
                isDraft: true,
                base64: selectedFile.base64!,
            }

            setFormDataFile(formDataFileResponse);
        }
        else {

            const fetchData = async () => {
                try {
                    if (selectedFile.fileGuid) {
                        const request: IFormDataFileRequest = {
                            userId: user.userId, formId: selectedForm.formId, customerId: selectedForm.customerId, fileGuid: selectedFile.fileGuid
                        };

                        const formDataFileResponse: IFormDataFileResponse = await GetFormDataFile(request);
                        if (formDataFileResponse) {

                            await indexedDBService.setItem("File", formDataFileResponse.fileName, formDataFileResponse.base64);
                            setFormDataFile(formDataFileResponse);
                        }
                    }
                } catch (error) {
                    showSnackbar('Error fetching data', 'error', 5000);
                }
            };
            fetchData();
        }
    }, []);

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Photo View
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {formDataFile && <img
                    srcSet={`${formDataFile.base64}`}
                    src={`${formDataFile.base64}`}
                    alt={"test"}
                    loading="lazy"
                />}
            </Dialog>
        </div>
    );
}
