
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';

import SelectControl from '../controls/select/SelectControl';
import selectControlTester from '../controls/select/selectControlTester';
import MediaCaptureControl from '../controls/media-capture/MediaCaptureControl';
import mediaCaptureControlTester from '../controls/media-capture/mediaCaptureControlTester';

import ImageCaptureControl from '../controls/image-capture/ImageCaptureControl';
import imageCaptureControlTester from '../controls/image-capture/imageCaptureControlTester';
import PhotoCaptureControl from '../controls/photo-capture/PhotoCaptureControl';
import photoCaptureControlTester from '../controls/photo-capture/photoCaptureControlTester';

const FormPreview = ({ schema, uischema, data, }) => {
    const renderers = [
        ...materialRenderers,
        { tester: selectControlTester, renderer: SelectControl },
        { tester: mediaCaptureControlTester, renderer: MediaCaptureControl },
        { tester: imageCaptureControlTester, renderer: ImageCaptureControl },
        { tester: photoCaptureControlTester, renderer: PhotoCaptureControl },
    ];

    return (
        <Grid container spacing={2} textAlign="center">
            <Grid item md={12} className='sho-form-container'>
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={data}
                    renderers={renderers}
                    cells={materialCells}
                // onChange={({ errors, data }) => setData(data)}
                />
            </Grid>
        </Grid>
    );
}

export default FormPreview;
