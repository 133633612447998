// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import rolesReducer from './rolesSlice';
import userResponseReducer from './userResponseSlice';
import selectedFormReducer from './selectedFormSlice';
import brandingReducer from './brandingSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        roles: rolesReducer,
        userDetail: userResponseReducer,
        selectedForm: selectedFormReducer,
        branding: brandingReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
