import { useEffect, useReducer, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import { Chip, FormControl, TextField } from '@mui/material';
import { GetFormSetting, SaveFormSetting } from '../services/form-service';
import { IFormSetting, IFormSettingRequest } from '../types/forms';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const initialState = {
    formSettingId: 0,
    formId: 0,
    customerId: 0,
    dropboxPath: "",
    subject: '',
    emails: [],
    bodyText: "",

    isValid: {
        dropboxPath: false,
        subject: false,
    },
    touched: {
        dropboxPath: false,
        subject: false,
    },
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'SET_VALID':
            return { ...state, isValid: { ...state.isValid, [action.field]: action.isValid } };
        case 'SET_TOUCHED':
            return { ...state, touched: { ...state.touched, [action.field]: true } };
        default:
            throw new Error();
    }
}

function isValidName(name) {
    return name.trim().length > 0;
}

function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function FormSettingDialog(props) {
    const { showDialog, selectedForm, handleClose, handleSave } = props;
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, {
        ...initialState
    });
    const user = useSelector((state: RootState) => state.userDetail);
    const { dropboxPath, subject, emails, bodyText, isValid, touched } = state;

    const [formSetting, setFormSetting] = useState<IFormSetting>();

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
        validateField(field, value);
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };

    const validateField = (field, value) => {
        let isValid = false;
        switch (field) {
            case 'dropboxPath':
            case 'subject':
            case 'bodyText':
                isValid = isValidName(value);
                break;
            default:
                break;
        }

        dispatch({ type: 'SET_VALID', field, isValid });
    };

    const [emailList, setEmailList] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value);
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const newEmail = inputValue.trim();
            if (newEmail && !emailList.includes(newEmail)) {
                if (isValidEmail(newEmail)) {
                    setEmailList([...emailList, newEmail]);
                    setInputValue('');
                }
            }
        }
    };

    const handleChipDelete = (email: string, index: number) => {
        setEmailList((prevEmails) => {
            const updatedEmails = [...prevEmails];
            updatedEmails.splice(index, 1);
            return updatedEmails;
        });
    };

    const isValidEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();


        try {
            const request: IFormSetting = {
                formId: selectedForm.formId,
                customerId: selectedForm.customerId,
                dropboxPath: dropboxPath,
                subject: subject,
                emails: emailList.join(","),
                bodyText: bodyText,
                userId: user.userId,
                formSettingId: 0,
                createdDate: new Date(),
                modifiedDate: new Date(),
                modifiedBy: user.userId,
                isDeleted: false
            };


            await SaveFormSetting(request);

            showSnackbar('Form Settings Saved Successfully', 'error', 5000);

            handleSave();
        } catch (error) {
            showSnackbar('Error fetching data', 'error', 5000);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request: IFormSettingRequest = { formId: selectedForm.formId };
                const formSettingResp = await GetFormSetting(request);
                if (formSettingResp.data.formSetting) {
                    setFormSetting(formSettingResp.data.formSetting);
                    // formSetting.drop

                    setField('dropboxPath', formSettingResp.data.formSetting.dropboxPath);
                    setField('subject', formSettingResp.data.formSetting.subject);
                    setField('bodyText', formSettingResp.data.formSetting.bodyText);

                    if (formSettingResp.data.formSetting.emails && formSettingResp.data.formSetting.emails != "") {
                        setEmailList(formSettingResp.data.formSetting.emails.split(","));
                    }
                }
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };
        fetchData();
    }, [selectedForm]);

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Settings :  {selectedForm.formName}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} className={classes.shoFormContainer}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Drop Box Url"
                                // hint="Example : /ShoForms/Files/"
                                value={dropboxPath}
                                onChange={(event) => setField('dropboxPath', event.target.value)}
                                onBlur={() => setTouched('dropboxPath')}
                                error={!isValid.dropboxPath && touched.dropboxPath}
                                helperText={!isValid.dropboxPath && touched.dropboxPath ? "Enter valid Dropbox Path" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Subject"
                                value={subject}
                                onChange={(event) => setField('subject', event.target.value)}
                                onBlur={() => setTouched('subject')}
                                error={!isValid.subject && touched.subject}
                                helperText={!isValid.subject && touched.subject ? "Enter valid Email Subject" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="Emails"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                onBlur={() => setTouched('emails')}
                                error={!isValid.emails && touched.emails}
                                helperText={!isValid.emails && touched.emails ? "Enter valid Email" : ""}
                            />
                            <div>
                                {emailList.map((email, index) => (
                                    <Chip
                                        key={index}
                                        label={email}
                                        onDelete={() => handleChipDelete(email, index)}
                                        style={{ margin: '0.5rem' }}
                                        color="primary"
                                    />
                                ))}
                            </div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Body Text"
                                value={bodyText}
                                multiline
                                rows={4}
                                onChange={(event) => setField('bodyText', event.target.value)}
                                onBlur={() => setTouched('bodyText')}
                                error={!isValid.bodyText && touched.bodyText}
                                helperText={!isValid.bodyText && touched.bodyText ? "Enter valid Email Template" : ""}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} justifyContent={'center'}>
                        <Button onClick={handleSubmit} variant="contained">
                            Save Form Settings
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
