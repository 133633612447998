import { Fragment, useState, useEffect, useReducer, useCallback } from 'react';
import * as React from 'react';
import debounce from 'lodash/debounce';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { IDownloadFormPdfRequest, IForm, IFormData, IFormDataDeleteRequest, IFormDataListRequest } from '../types/forms';
import { formatDateTime } from '../services/common.service';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DeleteFormData, DownloadFormPdf, GetFormDataList } from '../services/form-service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from './PageHeader';
import AddFormDataDialog from './AddFormDataDialog';
import indexedDBService from '../services/indexdb-service';
import { PageEnum } from '../types/enums';
import { withTheme } from '@emotion/react';

const initialState = {
    searchText: '',
};

const ViewFormDataList = () => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const [rows, setRows] = useState<IFormData[]>([]);

    const pagination = { page: 0, rowsPerPage: 5 };
    const [page, setPage] = useState(pagination.page);
    const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const user = useSelector((state: RootState) => state.userDetail);
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.FormEntires];

    const { formId, isReadOnlyMode } = useParams();
    const navigate = useNavigate();

    const [showAddEntryDialog, setShowAddEntryDialog] = useState(false);
    const [formGroupAllowsEditing, setFormGroupAllowsEditing] = useState(true);
    const [selectedForm, setSelectedForm] = React.useState<IForm>();
    const [selectedFormData, setSelectedFormData] = React.useState<IFormData>();
    const [idHeaderColumnName, setIdHeaderColumnName] = useState('');
    const [pageHeaderTitle, setPageHeaderTitle] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState
    });
    const { searchText } = state;

    const [initialLoad, setInitialLoad] = useState(true);

    function reducer(state, action) {
        switch (action.type) {
            case 'SET_FIELD':
                return { ...state, [action.field]: action.value };
            case 'SET_TOUCHED':
                return { ...state, touched: { ...state.touched, [action.field]: true } };
            default:
                throw new Error();
        }
    }

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };

    const debouncedGetFormDataList = useCallback(
        debounce((searchText) => {
            getFormDataList(searchText);
        }, 300), [] // No dependencies to ensure it doesn't change
    );

    useEffect(() => {

        if (isReadOnlyMode === "true" && user.roleId >= 5) {
            setFormGroupAllowsEditing(false);
        }

        const loadData = async () => {
            await getFormDataList();

            indexedDBService.getItem('General', 'GetFormDetailList')
                .then((formList: any) => {
                    if (formId) {
                        const formObj = formList.find(x => x.formId === parseInt(formId));
                        setSelectedForm(formObj);
                    }
                });
            setInitialLoad(false);
        };

        loadData();
    }, [formId, page, rowsPerPage]); // Only run once on initial render

    useEffect(() => {
        if (!initialLoad) {
            debouncedGetFormDataList(searchText);
        }
    }, [searchText]);

    const handleChangePage = (event: unknown, newPage: number) => {
        pagination.page = newPage;
        setPage(pagination.page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagination.rowsPerPage = +event.target.value;
        pagination.page = 0;
        setPage(pagination.page);
        setRowsPerPage(pagination.rowsPerPage);
    };

    const handleClose = () => {
        setShowAddEntryDialog(false);
        getFormDataList();
    };

    const getLocalRows = async (selectedFormId: number) => {
        const localRows: any = [];
        const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');

        if (storedData) {
            const formList = await indexedDBService.getItem('General', 'GetFormDetailList');
            const form = formList.find(x => x.formId === selectedFormId);
            storedData.forEach((req, index) => {
                if (req.data.formId === selectedFormId) {
                    localRows.push({
                        "uniqueId": req.data.uniqueId,
                        "formId": selectedFormId,
                        "formDataId": req.data.formDataId,
                        "rowIndex": index,
                        "formName": form.formName,
                        "createdDate": new Date().toISOString(),
                        "createdBy": user.userId,
                        "createdByName": user.name,
                        "modifiedDate": null,
                        "modifiedBy": null,
                        "modifiedByName": null,
                        "formData": JSON.parse(req.data.formDataJson),
                        "isDraft": true,
                        "formDataKeyFieldValue": req.data.formDataKeyFieldValue,
                        "formKeyFieldFriendlyName": req.data.formKeyFieldFriendlyName,
                        "isReadOnlyMode": req.data.isReadOnlyMode,
                    });
                }
            });
        }
        return localRows;
    };

    const getFormDataList = async (searchText?: string) => {
        const selectedFormId = formId ? parseFloat(formId) : 0;
        const request: IFormDataListRequest = {
            customerId: user.customerId,
            userId: user.userId,
            formId: selectedFormId,
            pageSize: rowsPerPage,
            pageNumber: page + 1,
            searchText: searchText
        };

        GetFormDataList(request)
            .then(async (response) => {
                const localRows: IFormData[] = await getLocalRows(selectedFormId);

                let formData: IFormData[] = [];

                if (localRows) {
                    formData = localRows;
                    if (response.formDataList) {
                        response.formDataList.forEach((formDataItem: IFormData) => {
                            const existsInLocalRows = formData.some(row => row.formDataId === formDataItem.formDataId);
                            if (!existsInLocalRows) {
                                formData.push(formDataItem);
                            }
                        });
                    }
                } else {
                    formData = response.formDataList;
                }

                if (response.formDataList && response.formDataList.length > 0) {
                    if (response.formDataList[0]) {
                        if (response.formDataList[0].formKeyFieldFriendlyName) {
                            setIdHeaderColumnName(response.formDataList[0].formKeyFieldFriendlyName);
                        } else {
                            setIdHeaderColumnName('Entry Id');
                        }
                        if (response.formDataList[0].formName) {
                            setPageHeaderTitle('Form Entries - ' + response.formDataList[0].formName);
                        } else {
                            setPageHeaderTitle('Form Entries');
                        }
                    }
                }
                else {
                    setPageHeaderTitle('Form Entries');
                }

                setRows(formData);
                setTotalRowCount(formData.length);
            })
            .catch(async (error) => {
                console.error('GetFormDataList error: ', error);
                if (!navigator.onLine) {
                    const localRows: any = await getLocalRows(selectedFormId);
                    setRows(localRows);
                }
            });
    };

    const onViewEntry = (row: IFormData) => {
        setSelectedFormData(row);
        setShowAddEntryDialog(true);
    };

    const onDeleteFormData = async (row: IFormData) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (!isConfirmed) {
            return; // If the user cancels, do nothing
        }

        try {
            setLoading(true);
            const request: IFormDataDeleteRequest = {
                formDataId: row.formDataId,
                userId: user.userId,
                uniqueId: row.uniqueId,
            };

            await DeleteFormData(request)
                .catch(async (error) => {
                    console.error('onDeleteFormData await DeleteFormData error: ', error);
                }).finally(() => {
                    getFormDataList();
                    setLoading(false);
                });
        }
        catch (err) {
            console.error('onDeleteFormData error: ', err);
            setLoading(false);
        }
    }

    const onDownloadPdf = async (row: IFormData) => {
        try {
            setLoading(true);
            const selectedFormId = formId ? parseFloat(formId) : 0;
            const request: IDownloadFormPdfRequest = {
                customerId: row.customerId,
                userId: user.userId,
                formId: selectedFormId,
                formDataId: row.formDataId,
                apiBaseUrl: "",
                formFileName: row.formName + "_" + row.formDataKeyFieldValue,
                formDataKeyFieldValue: row.formDataKeyFieldValue!,
            };

            await DownloadFormPdf(request)
                .catch(async (error) => {
                    console.error('onDownloadPdf await DownloadFormPdf error: ', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
        catch (err) {
            console.error('onDownloadPdf error: ', err);
            setLoading(false);
        }
    };

    const onBack = () => {
        navigate("/form-list");
    };

    const defaultFormData: IFormData = {
        formDataId: 0,
        formId: 0,
        customerId: 0,
        formName: "",
        createdDate: new Date(),
        createdBy: 0,
        createdByName: "",
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        uniqueId: 0,
        isDraft: true, // Assuming new entries start as drafts
        formDataKeyFieldValue: null,
        formKeyFieldFriendlyName: null
    };

    const onAddNewEntry = () => {
        let formDataNew: IFormData = { ...defaultFormData };
        setSelectedFormData(formDataNew);
        setShowAddEntryDialog(true);
    }

    return (
        <Fragment>
            <Grid container justifyContent={'center'} spacing={1}>
                {currentPage?.canView && (
                    <Grid item sm={12}>
                        {pageHeaderTitle && (
                            <Grid item sm={12}>
                                <PageHeader title={pageHeaderTitle} showBackButton={true} onBackClick={onBack} />
                            </Grid>
                        )}
                        {idHeaderColumnName && (
                            <Grid item sm={12} className="margin10">
                                <TextField
                                    label={idHeaderColumnName}
                                    value={searchText}
                                    onChange={(event) => setField('searchText', event.target.value)}
                                    onBlur={() => setTouched('searchText')}
                                    helperText="Start typing to search..."
                                />
                            </Grid>
                        )}
                        {loading && <Grid item sm={12} className="margin10"><p><b>PDF Generating. Please wait...</b></p></Grid>}
                        {formGroupAllowsEditing && currentPage?.canAdd && <Button onClick={e => onAddNewEntry()} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                            <AddCircleOutlineOutlinedIcon className="fs20 mr6 sho-icon" color='primary' />
                            Add Entry
                        </Button>
                        }
                        <Grid item sm={12} className="margin10">
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader size="small" aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {idHeaderColumnName && <TableCell key="formDataId">{idHeaderColumnName}</TableCell>}
                                                {!idHeaderColumnName && <TableCell key="formDataId">Entry ID</TableCell>}
                                                {!isMobile && <TableCell key="createdBy">Entry By</TableCell>}
                                                {!isMobile && <TableCell key="createdDate">Entry Date</TableCell>}
                                                {formGroupAllowsEditing && <TableCell key="isDraft" align="center">Draft?</TableCell>}
                                                <TableCell key="actions" align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row: IFormData) => (
                                                <TableRow hover tabIndex={-1} key={row.formDataId}>
                                                    {row.formDataKeyFieldValue ? (
                                                        <TableCell key="formDataId">
                                                            {row.formDataKeyFieldValue}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key="formDataId">
                                                            {row.formDataId > 0 ? row.formDataId : row.uniqueId}
                                                        </TableCell>
                                                    )}
                                                    {!isMobile && <TableCell key="createdBy">{row.createdByName}</TableCell>}
                                                    {!isMobile && <TableCell key="createdDate">{formatDateTime(row.createdDate)}</TableCell>}
                                                    {formGroupAllowsEditing && <TableCell key="isDraft">{row.isDraft ? "Yes" : "No"}</TableCell>}
                                                    <TableCell key="actions" align="center">
                                                        {formGroupAllowsEditing && isMobile &&
                                                            <VisibilityIcon onClick={e => onViewEntry(row)} className="fs20 mr6 sho-icon" color='primary' />
                                                        }
                                                        {formGroupAllowsEditing && !isMobile &&
                                                            <Button onClick={e => onViewEntry(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <VisibilityIcon className="fs20 mr6" />
                                                                View Entry
                                                            </Button>
                                                        }

                                                        {row.formDataId > 0 && isMobile &&
                                                            <DownloadIcon onClick={e => onDownloadPdf(row)} className="fs20 mr6 sho-icon" color='primary' />
                                                        }

                                                        {row.formDataId > 0 && !isMobile && (
                                                            <Button onClick={e => onDownloadPdf(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <DownloadIcon className="fs20 mr6" />
                                                                Download PDF
                                                            </Button>
                                                        )}

                                                        {formGroupAllowsEditing && isMobile &&
                                                            <DeleteIcon onClick={e => onDeleteFormData(row)} className="fs20 mr6 sho-icon" color='primary' />
                                                        }

                                                        {formGroupAllowsEditing && !isMobile && (
                                                            <Button onClick={e => onDeleteFormData(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <DeleteIcon className="fs20 mr6" />
                                                                Discard Draft
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 50, 100]}
                                    component="div"
                                    count={totalRowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )}
                {!currentPage?.canView && <h1>Loading Form Entries...</h1>}
            </Grid>

            {showAddEntryDialog && (
                <AddFormDataDialog
                    showDialog={showAddEntryDialog}
                    form={selectedForm}
                    formData={selectedFormData}
                    handleClose={handleClose}
                />
            )}
        </Fragment>
    );
};

export default withTheme(ViewFormDataList);