import { Fragment, useState } from 'react';
import * as React from 'react';

import Grid from '@mui/material/Grid';

import FormList from '../components/FormList';
import PageHeader from '../components/PageHeader';
import AddFormDataDialog from '../components/AddFormDataDialog';
import { useDispatch } from 'react-redux';
import { updateSelectedForm } from '../store/selectedFormSlice';

import { useStyles } from "../common/appStyles";
import { IForm } from '../types/forms';

export default function MyForms() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const currentViewEnum = {
        MyForms: "MyForms",
    };

    const pageTitleEnum = {
        MyForms: "My Forms"
    };

    const [showAddEntryDialog, setShowAddEntryDialog] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const [currentView, setCurrentView] = useState(currentViewEnum.MyForms);
    const [selectedForm, setSelectedForm] = React.useState<IForm>();
    const [selectedFormData, setSelectedFormData] = React.useState({});

    const [pageTitle, setPageTitle] = useState(pageTitleEnum.MyForms);

    const onAddNewEntry = (row: IForm) => {
        setSelectedFormData({});
        setSelectedForm(row);
        setShowAddEntryDialog(true);

        dispatch(updateSelectedForm(row));
    }

    const handleClose = () => {
        setShowAddEntryDialog(false)
    }

    const handleSave = () => {
        setShowAddEntryDialog(false)
    }

    const onBack = () => {
        setPageTitle(pageTitleEnum.MyForms);
        setShowBackButton(false);
        setCurrentView(currentViewEnum.MyForms);
    }

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12}>
                    <PageHeader title={pageTitle} showBackButton={showBackButton} onBackClick={onBack} />
                </Grid>
                <Grid item sm={12}>
                    {currentView === 'MyForms' && !showAddEntryDialog && <FormList onAddNewEntry={onAddNewEntry} />}

                    {showAddEntryDialog && <AddFormDataDialog
                        showDialog={showAddEntryDialog}
                        form={selectedForm}
                        formData={selectedFormData}
                        handleClose={handleClose}
                        handleSave={handleSave} />}

                </Grid>
            </Grid>
        </Fragment>
    );
}
